import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {userSignIn} from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";

import {forgotPass} from 'actions/User';

const ForgotPass = (props) => {
  //apps@focus-on.gr //12345678
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const [loadingForgotPass,setLoadingForgotPass] = useState(false);

  const forgotPassResponse = useSelector(({user}) => user.forgotPass);

  useEffect(() => {
    if (forgotPassResponse !== null) {
      if (forgotPassResponse.status) {
        NotificationManager.listNotify = [];
        NotificationManager.success(forgotPassResponse.message);
        setLoadingForgotPass(false);
        window.location.replace('/');
      } else {
        setLoadingForgotPass(false);
        NotificationManager.listNotify = [];
        NotificationManager.error(forgotPassResponse.message);
      }
    }
  }, [forgotPassResponse]);

  const onForgotPassClick = (e) => {
    e.preventDefault();
    setLoadingForgotPass(true);
    dispatch(forgotPass(email));
  }

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="GHPService">
            <img src={require("assets/images/logo.png")} alt="ghp" title="ghp"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Ξέχασα τον κωδικό μου </h1>
          </div>

          <div className="app-login-form">
            <p>Συμπληρώστε το email του λογαριασμού σας και θα λάβετε ένα email με οδηγίες για αλλαγή του κωδικού πρόσβασης. </p>
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email"/>}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
         

                <div style={{display:'flex',flexDirection: 'row',alignItems: 'center',width:'100%',justifyContent:'center',paddingTop: '24px',paddingBottom:'16px'}} className="">
                  <Button disabled={loadingForgotPass || email == ""} onClick={(e) => {
                      onForgotPassClick(e)
                  }} variant="contained" color="primary">
                    ΥΠΟΒΟΛΗ
                  </Button>
                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>

  
     
      <NotificationContainer/>

    </div>
  );
};


export default ForgotPass;
