import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';

import {useSelector} from "react-redux";

const Routes = ({match}) => {

const userData = useSelector(({auth}) => auth.authUser);

 return(<Switch>
                
    <Route path={`${match.url}/dashboard`}
           component={asyncComponent(() => import('./Dashboard'),userData)}/>
    <Route path={`${match.url}/estates`}
            component={asyncComponent(() => import('./Estates'),userData)}/>
    <Route path={`${match.url}/users`}
            component={asyncComponent(() => import('./Users'),userData)}/>
    <Route path={`${match.url}/estate-admins`}
            component={asyncComponent(() => import('./Users'),userData)}/>
    <Route path={`${match.url}/vehicles`}
            component={asyncComponent(() => import('./Vehicles'),userData)}/>
    <Route path={`${match.url}/store`}
            component={asyncComponent(() => import('./Store'),userData)}/>
    <Route path={`${match.url}/notifications`}
            component={asyncComponent(() => import('./Notifications'),userData)}/>
    <Route path={`${match.url}/questionnaires`}
            component={asyncComponent(() => import('./Questionnaire'),userData)}/>
    <Route path={`${match.url}/reminders`}
            component={asyncComponent(() => import('./Reminders'),userData)}/>
    <Route path={`${match.url}/damage-companies`}
            component={asyncComponent(() => import('./DamageCompanies'),userData)}/>
    <Route path={`${match.url}/reports`}
            component={asyncComponent(() => import('./Reports'),userData)}/>
    <Route path={`${match.url}/keys/categories`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateKeys/EstateKeysCategories'),userData)}/>
    <Route path={`${match.url}/lamps/categories`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateLamps/EstateLampsCategories'),userData)}/>
    <Route path={`${match.url}/vehicle-service/categories`}
            component={asyncComponent(() => import('./Vehicles/routes/Single/VehicleService/VehicleServiceCategories'),userData)}/>
    <Route path={`${match.url}/service-tasks`}
            component={asyncComponent(() => import('./Estates/routes/Single/EstateTasks'),userData)}/>
    <Route path={`${match.url}/chat`}
           component={asyncComponent(() => import('./Chat/ChatRoom'),userData)}/>

    {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"),userData)}/>*/}

  </Switch>);

 }

export default withRouter(Routes);
