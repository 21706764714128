import firebase from 'firebase';
import Api from '../util/Api';
import * as Const from '../constants/values';

var firebaseConfig =  {
  apiKey: "AIzaSyDlSACFmiS75NVJfeCjBGDDL2CIqSV7xTs",
  authDomain: "ghp-erp.firebaseapp.com",
  databaseURL: "https://ghp-erp.firebaseio.com",
  projectId: "ghp-erp",
  storageBucket: "ghp-erp.appspot.com",
  messagingSenderId: "827788048995",
  appId: "1:827788048995:web:7f84e2e8c1d628f4a51656",
  measurementId: "G-EFLCF8X6T3"
};

export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();


  // navigator.serviceWorker
  //   .register('/my-sw.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });


}


export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();
    const token = await messaging.getToken();

    // let userId = JSON.parse(localStorage.getItem('userId'));
    // if (userId !== ""){
    //   subscribeToTopic(`${Const.ARG_FIREBASE_TOPIC_USER}${userId}`);
    // }




    return token;
  } catch (error) {
    console.error(error);
  }
}

export const subscribeToTopic = async (topic) => {
    try {
        const messaging = firebase.messaging();
        messaging.requestPermission();
        const token =  messaging.getToken();

        let postData = {topic,token};

        Api.post('/settings/subscribeToTopic', postData)
        .then(function (response) {
          if (Const.DEBUG_MODE) {
            console.log(response);
          }
        })
        .catch(function (error) {
          if (Const.DEBUG_MODE) {
            console.log(error);
          }
        });
    }catch (e) {
      console.log(e);
    }
}

export const unsubscribeToTopic = async (topic) => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    let postData = {topic,token};

    Api.post('/settings/unsubscribeToTopic', postData)
    .then(function (response) {
      if (Const.DEBUG_MODE) {
        console.log(response);
      }
    })
    .catch(function (error) {
      if (Const.DEBUG_MODE) {
        console.log(error);
      }
    });
  }catch (e) {
    console.log(e);
  }
}

export const logEvent = async (eventName,eventData) => {
  const analytics = firebase.analytics();
  analytics.logEvent(eventName,eventData)
}
