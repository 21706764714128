import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  REPORT_ESTATE_SERVICES,
  REPORT_ESTATE_GENERAL,
  REPORT_VEHICLE_GENERAL,
  REPORT_STORE_GENERAL,
  REPORT_USER_NOTIFICATIONS,
  REPORT_REMINDERS
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getReportEstateServices = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/estate-services', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_ESTATE_SERVICES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getReportEstateGeneral = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/estate-general', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_ESTATE_GENERAL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getReportVehicleGeneral = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/vehicle-general', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_VEHICLE_GENERAL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const getStoreReportGeneral = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/store-general', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_STORE_GENERAL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUserNotificationsReport = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/user-notifications', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_USER_NOTIFICATIONS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getRemindersReport = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('report/reminders', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: REPORT_REMINDERS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
