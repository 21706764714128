import React from 'react';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">@{new Date().getFullYear()} GHP SERVICE – POWERED BY CLICKGATE - {` Έκδοση: ${process.env.REACT_APP_VERSION}`}</span>
        <Button
          href="mailto:apps@focus-on.gr"
          target="_blank"
          size="small"
          color="primary"
        ><IntlMessages id="ΥΠΟΣΤΗΡΙΞΗ"/></Button>
      </footer>
    );
  }
;

export default Footer;
