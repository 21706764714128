import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_STORE_AUTH_HOLDER,
  EDIT_STORE_AUTH_HOLDER,
  SINGLE_STORE_AUTH_HOLDER,
  FETCH_STORE_AUTH_HOLDER,
  DELETE_STORE_AUTH_HOLDER
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getStoreAuthHolders = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeAuthHolder/all', postData
    ).then(({data}) => {
      console.log(data);
      if (data){

        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_STORE_AUTH_HOLDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addStoreAuthHolder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeAuthHolder/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_STORE_AUTH_HOLDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editStoreAuthHolder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeAuthHolder/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_STORE_AUTH_HOLDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleStoreAuthHolder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeAuthHolder/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_STORE_AUTH_HOLDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteStoreAuthHolder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeAuthHolder/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_STORE_AUTH_HOLDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
