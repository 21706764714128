import {
  ADD_EDIT_QUESTIONARE,
  ALL_QUESTIONARES_LIST,
  DELETE_QUESTIONARE,
  SINGLE_QUESTIONARE,
  EDIT_QUESTIONS_QUESTIONARE,
  ADD_EDIT_ISSUANCE_QUESTIONARE,
  DELETE_ISSUANCE_QUESTIONARE,
  ESTATE_QUESTIONARES,
  QUESTIONARE_WITH_ISSUANCE,
  QUESTIONARE_ANSWER_ADD_EDIT,
  ANSWERED_QUESTIONARES_DATA
} from '../constants/ActionTypes';

const INIT_STATE = {
  addEditQuestionnaireData: null,
  questionnairesListData: null,
  singleQuestionnaireData: null,
  editQuestionsData: null,
  addEditIssuanceData: null,
  deleteIssuanceData: null,
  deleteQuestionnaireData: null,
  estateQuestionaresData: null,
  questionnaireWithIssuanceData: null,
  questionnaireAnswerAddEdit: null,
  answeredQuestionnaresData: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EDIT_QUESTIONARE:
      return {
        ...state,
        addEditQuestionnaireData: action.payload
      };
    case ALL_QUESTIONARES_LIST:
      return {
        ...state,
        questionnairesListData: action.payload
      };
    case DELETE_QUESTIONARE:
      return {
        ...state,
        deleteQuestionnaireData: action.payload
      };
    case SINGLE_QUESTIONARE:
      return {
        ...state,
        singleQuestionnaireData: action.payload
      };
    case EDIT_QUESTIONS_QUESTIONARE:
      return {
        ...state,
        editQuestionsData: action.payload
      };
    case ADD_EDIT_ISSUANCE_QUESTIONARE:
      return {
        ...state,
        addEditIssuanceData: action.payload
      };
    case DELETE_ISSUANCE_QUESTIONARE:
      return {
        ...state,
        deleteIssuanceData: action.payload
      };
    case ESTATE_QUESTIONARES:
      return {
        ...state,
        estateQuestionaresData: action.payload
      };
    case QUESTIONARE_WITH_ISSUANCE:
      return {
        ...state,
        questionnaireWithIssuanceData: action.payload
      };
    case QUESTIONARE_ANSWER_ADD_EDIT:
      return {
        ...state,
        questionnaireAnswerAddEdit: action.payload
      };
    case ANSWERED_QUESTIONARES_DATA:
      return {
        ...state,
        answeredQuestionnaresData: action.payload
      };
    default:
      return state;
  }
}
