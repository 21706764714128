import React, {useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import Header from "./Header/index";
import SideBar from "containers/SideBar/index";
import Footer from "components/Footer";
import Tour from "components/Tour/index";
import {getUser,userSignOut} from 'actions/Auth';
import {Redirect, Route, Switch} from "react-router-dom";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import { unsubscribeToTopic, logEvent } from 'firebase/FirebaseInit';
import * as Const from "constants/values";
import Loader from "react-loader-spinner";

const Vertical =(props)=> {

  const dispatch = useDispatch();

  const { drawerType } = useSelector(({settings})=>settings);
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "fixed-drawer" : drawerType.includes(COLLAPSED_DRAWER) ? "collapsible-drawer" : "mini-drawer";
    const userData = useSelector(({auth}) => auth.authUser);

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    useEffect(() => {
      let userId = JSON.parse(localStorage.getItem('userId'));
      if (userId !== null) {
        dispatch(getUser(userId));
      }
    }, []);

    if (userData == null) {
        return(
          <div className="loader-view"
          style={{height: 'calc(100vh - 200px)'}}>
        <Loader
                     type="ThreeDots"
                     color="#87D148"
                     height={70}
                     width={70}
                 />
     </div>
        );
    } else {
      if (userData.status != null && userData.status == false) {
        let userId = JSON.parse(localStorage.getItem('userId'));
        unsubscribeToTopic(`${Const.ARG_FIREBASE_TOPIC_USER}${userId}`);
        dispatch(userSignOut());
        return (<Redirect to={'/signin'}/>);
      } else {
      return (
        <div className={`app-container ${drawerStyle}`}>
          <Tour/>

          <SideBar/>
          <div className="app-main-container">
            <div className="app-header">
              <Header/>
            </div>

            <main className="app-main-content-wrapper">
              <div className="app-main-content">
                {props.children}
              </div>
              <Footer/>
            </main>
          </div>
        </div>
      );
    }
  };
}



export default withRouter(Vertical);
