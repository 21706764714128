import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {userSignIn} from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";

const SignIn = (props) => {
  //apps@focus-on.gr //12345678
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  const loginData = useSelector(({auth}) => auth.loginData);
  const {loading, message} = useSelector(({common}) => common);

  useEffect(() => {
    if (token !== null) {
     // props.history.push('/');
      if (loginData != null && loginData.status){
        window.location.replace('/');
      }
    }
  }, [message,token,loginData]);

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="GHPService">
            <img src={require("assets/images/logo.png")} alt="ghp" title="ghp"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.signIn"/></h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id="appModule.email"/>}
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password"/>}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div style={{display:'flex',flexDirection: 'row',alignItems: 'end',width:'100%',justifyContent:'end'}}>
                  <a style={{color: 'grey',fontSize: '13px',paddingTop: '8px'}} href='/forgot-password'>Ξεχάσατε τον κωδικό σας; </a>
                </div>
                

                <div style={{display:'flex',flexDirection: 'row',alignItems: 'center',width:'100%',justifyContent:'center',paddingTop: '24px',paddingBottom:'16px'}} className="">
                  <Button onClick={() => {
                    dispatch(userSignIn({email, password}));
                  }} variant="contained" color="primary">
                    <IntlMessages id="appModule.signInBtn"/>
                  </Button>
                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {
        loading ?
        <div className="loader-view">
          <CircularProgress/>
        </div> : null
      }
      {message && NotificationManager.error(message)}
      <NotificationContainer/>

    </div>
  );
};


export default SignIn;
