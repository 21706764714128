import { userServicesEstateIds } from 'actions';
import { FETCH_ESTATES_PENDING, FETCH_ESTATES_SUCCESS, FETCH_ESTATES_ERROR, CREATE_ESTATE, EDIT_ESTATE, SINGLE_ESTATE,USER_SERVICES_ESTATES } from '../constants/ActionTypes';

const INIT_STATE = {
    estateData: null,
    createEstate: null,
    editEstate: null,
    singleEstate: null,
    userServicesEstatesData:null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ESTATES_SUCCESS:
            return {
                ...state,
                estateData: action.payload
            }
        case CREATE_ESTATE:
            return {
                ...state,
                createEstate: action.payload
            }
        case EDIT_ESTATE:
            return {
                ...state,
                editEstate: action.payload
            }
        case SINGLE_ESTATE:
            return {
                ...state,
                singleEstate: action.payload
            }
            case USER_SERVICES_ESTATES:
                return {
                    ...state,
                    userServicesEstatesData: action.payload
                }
            
        default:
            return state;
    }
}
