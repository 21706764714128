import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_ESTATE_OILS,
  FILL_ESTATE_OIL,
  EDIT_ESTATE_OIL,
  DELETE_ESTATE_OIL
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getEstateOils = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('oil/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_OILS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const fillEstateOil = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('oil/fill', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FILL_ESTATE_OIL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editEstateOil = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('oil/edit', postData
    ).then(({data}) => {
      // console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_OIL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteEstateOil = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('oil/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ESTATE_OIL, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
