//custom app constants
export const GENERAL_ERROR = "Παρουσιάστηκε σφάλμα. Παρακαλώ προσπαθήστε ξανά αργότερα.";
export const DEBUG_MODE = true;



//FIREBAE TOPICS
export const ARG_FIREBASE_TOPIC_USER = "topic_user_"
export const ARG_FIREBASE_TOPIC_ALL = "topic_user_all"

//SOCKET URL
export const SOCKET_URL = `https://api.ghpservice.com`
export const DATE_TIME_FORMAT = "DD/MM/yyyy HH:mm"

export const USER_ROLE_ESTATE_ADMIN = 5;

export const ESTATE_BUILD_TYPE_BLOCK = 1;
export const ESTATE_BUILD_TYPE_BUILDING = 2;

export const genders = [
  {
    value: '1',
    label: 'Άνδρας',
  },
  {
    value: '2',
    label: 'Γυναίκα'
  },
];

export const roles = [
  {
    value: '1',
    label: 'Admin',
  },
  {
    value: '2',
    label: 'Manager'
  },
  {
    value: '3',
    label: 'Καθαριστής'
  },
  {
    value: '4',
    label: 'Οδηγός'
  },
  {
    value: '5',
    label: 'Διαχειριστής'
  },
];

export const rolesNoEstateAdmin = [
  {
    value: '1',
    label: 'Admin',
  },
  {
    value: '2',
    label: 'Manager'
  },
  {
    value: '3',
    label: 'Καθαριστής'
  },
  {
    value: '4',
    label: 'Οδηγός'
  }
];

export const userstatuses = [
  {
    value: true,
    label: 'Ενεργός',
  },
  {
    value: false,
    label: 'Ανενεργός'
  }
]

export const serviceTypes = [
  {
    value: '1',
    label: 'Επαναλαμβανόμενη',
  },
  {
    value: '2',
    label: 'Περιοδική'
  },
  {
    value: '3',
    label: 'Μεμονωμένη'
  }
];

export const serviceStatuses = [
  {
    value: true,
    label: 'Ενεργή',
  },
  {
    value: false,
    label: 'Ανενεργή'
  }
];

export const estateStatuses = [
  {
    value: true,
    label: 'Ενεργή',
  },
  {
    value: false,
    label: 'Ανενεργή'
  }
];


export const days = [
  {
    value: '1',
    label: 'Δευτέρα',
  },
  {
    value: '2',
    label: 'Τρίτη'
  },
  {
    value: '3',
    label: 'Τετάρτη',
  },
  {
    value: '4',
    label: 'Πέμπτη'
  },
  {
    value: '5',
    label: 'Παρασκευή',
  },
  {
    value: '6',
    label: 'Σάββατο'
  },
  {
    value: '0',
    label: 'Κυριακή'
  }
];

export const contactMethod = [
  {
    value: '1',
    label: 'Σταθερό',
  },
  {
    value: '2',
    label: 'Κινητό'
  },
  {
    value: '3',
    label: 'Email'
  }
];


export const reportsType = [
  {
    title: 'Υπηρεσίες πολυκατοικιών',
    slug: 'estate-services'
  },
  {
    title: 'Γενικά πολυκατοικιών',
    slug: 'estate-general'
  },
  {
    title: 'Οχήματα',
    slug: 'vehicles'
  },
  {
    title: 'Αποθήκη',
    slug: 'store'
  },
  {
    title: 'Συνομιλίες',
    slug: 'messages'
  },
  {
    title: 'Υπενθυμίσεις',
    slug: 'reminders'
  },
]

export const reportsTypeUser = [
  {
    title: 'Υπηρεσίες πολυκατοικιών',
    slug: 'estate-services'
  },
  {
    title: 'Γενικά πολυκατοικιών',
    slug: 'estate-general'
  },
]



export const reportServiceTypes = [
  {
      type: 4,
      title: "Όλες"
  },
  {
      type: 1,
      title: "Επαναλαμβανόμενες"
  },
  {
      type: 2,
      title: "Περιοδικές"
  },
  {
      type: 3,
      title: "Μεμονωμένες"
  }
];

export const reportServiceStatus = [
  {
      type: 4,
      title: "Όλες"
  },
  {
      type: 1,
      title: "Ολοκληρωμένες"
  },
  {
      type: 2,
      title: "Μη ολοκληρωμένες"
  },
  {
      type: 3,
      title: "Να γίνουν"
  },
  {
    type: 5,
    title: "Με εκκρεμότητες"
  },
  {
    type: 6,
    title: "Με σχόλια"
  }
];


export const reportServiceReportTypes = [
  {
      type: 1,
      title: "Αναφορά εργασιών"
  },
  {
      type: 2,
      title: "Στατιστικά"
  }
];

export const reportEstateGeneralTypes = [
  {
      type: 1,
      title: "Κάτω από το όριο ασφαλείας στις μετρήσεις"
  },
  {
      type: 2,
      title: "Γεμίσματα πετρελαίου"
  },
  {
    type: 3,
    title: "Μετρήσεις πετρελαίου"
  },
  {
    type: 4,
    title: "Λάμπες"
  },
  {
    type: 5,
    title: "Ωρομετρητές διαμερισμάτων"
  },
  {
    type: 6,
    title: "Λογαριασμοί πολυκατοικίας-Κτηρίου"
  }, 
  {
    type: 7,
    title: "Βλάβες πολυκατοικίας-Κτηρίου"
  },
  {
    type: 8,
    title: "Αναφορά Κατανάλωσης ανά Πολυκατοικία"
  }
];

export const lampStatusTypes = [
  {
      type: 1,
      title: "Έχουν αλλαχθεί"
  },
  {
      type: 2,
      title: "Δεν έχουν αλλαχθεί"
  },
  {
    type: 3,
    title: "Όλες"
  }
];


export const billTypes = [
    {
      type: "all",
      title: "Όλα"
    },
  {
      type: "power",
      title: "Ρεύμα"
  },
  {
      type: "water",
      title: "Νερό "
  },
    {
      type: "gas",
      title: "Φυσικό Αέριο"
  },
 
];

export const vehicleReportTypes = [
  {
    id: 1,
    type: "VehicleExhaustControlCard",
    title: "Κάρτα καυσαερίων"
  },
{
    id: 2,
    type: "VehicleFuel",
    title: "Γεμίσματα"
},
{
  id: 3,
    type: "VehicleInsuranceCheck",
    title: "Ασφάλεια"
},
  {
    id: 4,
    type: "VehicleKteoCheck",
    title: "Έλεγχοι Κ.Τ.Ε.Ο"
  },
  {
    id: 5,
    type: "VehicleMovement",
    title: "Κινήσεις"
  },
  {
    id: 6,
    type: "VehicleOilCheck",
    title: "Έλεγχοι λαδιών"
  },
  {
    id: 7,
    type: "VehicleService",
    title: "Σέρβις Οχημάτων"
  },
  {
    id: 8,
    type: "VehicleTiresCheck",
    title: "Έλεγχοι ελαστικών"
  },
  {
    id: 9,
    type: "VehicleWash",
    title: "Πλυσίματα"
  },
];


export const storeReportTypes = [
  {
    id: 1,
    type: "receipts",
    title: "Παράλαβες"
  },
{
    id: 2,
    type: "supply",
    title: "Προμήθειες"
},
{
  id: 3,
    type: "order",
    title: "Παραγγελίες"
},
{
  id: 4,
    type: "receiptUser",
    title: "Προμήθειες ανά συνεργείο"
}
];

export const reminderReportTypes = [
  {
    id: 1,
    type: "reminders",
    title: "Γενικές υπενθυμίσεις"
  },
  {
      id: 2,
      type: "lisences",
      title: "Διπλώματα"
  },
  {
    id: 3,
      type: "adr",
      title: "ADR"
  }
];

export const estateTypes = [
  {
    id: 0,
    type: "all",
    title: "Όλοι"
  },
  {
      id: 1,
      type: "estates",
      title: "Πολυκατοικίες"
  },
  {
    id: 3,
      type: "buildings",
      title: "Κτήρια"
  }
];