import axios from 'axios';

const envType = process.env.NODE_ENV;

const isDev = envType == "development";

export default axios.create({
  baseURL: isDev ? "https://api.ghpservice.com/v1/" : `https://api.ghpservice.com/v1/`,//YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  }
});
