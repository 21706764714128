import {FILL_ESTATE_OIL, FETCH_ESTATE_OILS, DELETE_ESTATE_OIL,EDIT_ESTATE_OIL } from '../constants/ActionTypes';

const INIT_STATE = {
  fillOilData: null,
  estateOilData: null,
  deleteOilData: null,
  editOilData: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FILL_ESTATE_OIL:
       return {
           ...state,
           fillOilData: action.payload
       }
   case FETCH_ESTATE_OILS:
        return {
            ...state,
            estateOilData: action.payload
        }
   case DELETE_ESTATE_OIL:
         return {
                 ...state,
                 deleteOilData: action.payload
        }
   case EDIT_ESTATE_OIL:
        return {
              ...state,
              editOilData: action.payload
   }
             
  default:
        return state;
  }
}
