import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_STORE_ORDER,
  EDIT_STORE_ORDER,
  SINGLE_STORE_ORDER,
  FETCH_STORE_ORDER,
  DELETE_STORE_ORDER
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getStoreOrders = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeOrder/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_STORE_ORDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addStoreOrder = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeOrder/add', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_STORE_ORDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editStoreOrder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeOrder/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_STORE_ORDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleStoreOrder = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeOrder/single', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_STORE_ORDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteStoreOrder = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('storeOrder/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_STORE_ORDER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
