import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch,useSelector} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { unsubscribeToTopic, logEvent } from 'firebase/FirebaseInit';
import * as Const from "constants/values";

const UserInfo = () => {

  const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const userData = useSelector(({auth}) => auth.authUser);

  const [fullName, setFullName] = useState('');
  const [imgThumb, setImgThumb] = useState('');

  useEffect(() => {
    if (userData){
      //vALUES
      setFullName(userData.fullName);
      setImgThumb(userData.img_thumb);
    }
  }, [userData]);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };


  return (
    <div className="user-profile d-flex flex-row align-items-center">
      <Avatar
        alt='...'
        src={imgThumb}
        className="user-avatar "
      />
      <div className="user-detail">
          <h4 className="user-name d-flex" onClick={handleClick}>
          <span className='text-truncate'>{fullName}</span>
          <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
        </h4>
      </div>
      <Menu className="user-info"
            id="simple-menu"
            anchorEl={anchorE1}
            open={open}
            onClose={handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
      >
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
          <IntlMessages id="popup.profile"/>
        </MenuItem>
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
          <IntlMessages id="popup.setting"/>
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          let userId = JSON.parse(localStorage.getItem('userId'));
          unsubscribeToTopic(`${Const.ARG_FIREBASE_TOPIC_USER}${userId}`);
          dispatch(userSignOut());
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

          <IntlMessages id="popup.logout"/>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserInfo;
