import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'

//OTHER
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Dashboard from './Dashboard';

//USERS
import User from './User';

//ESTATES
import Estates from './Estates';
import EstateService from './EstateService';
import Apartments from './Apartments';
import Oils from './Oils';
import Lamps from './Lamps';
import Keys from './Keys';
import Task from './Task';

//VEHICLES
import Vehicles from './Vehicles';
import VehicleWash from './VehicleWash';
import VehicleTiresCheck from './VehicleTiresCheck';
import VehicleServiceCategory from './VehicleServiceCategory';
import VehicleService from './VehicleService';
import VehicleOilCheck from './VehicleOilCheck';
import VehicleKteoCheck from './VehicleKteoCheck';
import VehicleInsuranceCheck from './VehicleInsuranceCheck';
import VehicleFuel from './VehicleFuel';
import VehicleMovement from './VehicleMovement';
import VehicleExhaustControlCard from './VehicleExhaustControlCard';

//STORE
import StoreCategory from './StoreCategory';
import StoreAuthHolder from './StoreAuthHolder';
import StoreSupplier from './StoreSupplier';
import StoreSeller from './StoreSeller';
import StoreProduct from './StoreProduct';
import StoreReceipt from './StoreReceipt';
import StoreUserSupply from './StoreUserSupply';
import StoreOrder from './StoreOrder';

//NOTIFICATIONS
import Notifications from './Notifications';

//REMINDERS
import Reminders from './Reminders';

//BILLS
import EstateBill from './EstateBill';
import Report from './Report';
import Chat from './Chat';

//DAMAGE COMPANIES
import DamageCompanies from './DamageCompanies';
import Questionnaires from './Questionnaires';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  estates: Estates,
  apartments: Apartments,
  oils: Oils,
  user: User,
  lamps: Lamps,
  keys: Keys,
  dashboard: Dashboard,
  vehicles: Vehicles,
  vehicleWash: VehicleWash,
  vehicleTiresCheck: VehicleTiresCheck,
  vehicleServiceCategory: VehicleServiceCategory,
  vehicleService: VehicleService,
  vehicleOilCheck: VehicleOilCheck,
  vehicleKteoCheck: VehicleKteoCheck,
  vehicleInsuranceCheck: VehicleInsuranceCheck,
  vehicleFuel: VehicleFuel,
  vehicleMovement: VehicleMovement,
  vehicleExhaustControlCard: VehicleExhaustControlCard,
  estateService: EstateService,
  task: Task,
  storeCategory: StoreCategory,
  storeAuthHolder: StoreAuthHolder,
  storeSupplier:StoreSupplier,
  storeSeller:StoreSeller,
  storeProduct: StoreProduct,
  storeReceipt:StoreReceipt,
  storeUserSupply:StoreUserSupply,
  storeOrder:StoreOrder,
  notifications: Notifications,
  reminders: Reminders,
  estateBill: EstateBill,
  report: Report,
  chat: Chat,
  damageCompanies: DamageCompanies,
  questionnaires: Questionnaires
});
