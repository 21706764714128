import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  ADD_ESTATE_KEY_CATEGORY,
  EDIT_ESTATE_KEY_CATEGORY,
  FETCH_ESTATE_KEYS_CATEGORIES,
  ADD_ESTATE_KEY,
  EDIT_ESTATE_KEY,
  FETCH_ESTATE_KEYS,
  DELETE_ESTATE_KEY_CATEGORY,
  DELETE_ESTATE_KEY
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getKeysCategories = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/category/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_KEYS_CATEGORIES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addNewCategory = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/category/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_ESTATE_KEY_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editNewCategory = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/category/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_KEY_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteCategory = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/category/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ESTATE_KEY_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addEstateKey = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_ESTATE_KEY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editEstateKey = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_KEY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteEstateKey = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ESTATE_KEY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getEstateKeys = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estateKey/all', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_KEYS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
