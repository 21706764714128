import React, {Component} from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from "../components/CircularProgress/index";
import Loader from "react-loader-spinner";
import {Redirect, Route, Switch} from "react-router-dom";
import { USER_ROLE_ESTATE_ADMIN } from 'constants/values';

export default function asyncComponent(importComponent,userData) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const {default: Component} = await importComponent();
      Nprogress.done();
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />
        });
      }
    }

    render() {


      if (userData != null && userData.role == USER_ROLE_ESTATE_ADMIN){
        if (!this.props.match.path.includes("estates") && !this.props.match.path.includes("reports") && !this.props.match.path.includes("chat")){
          return <Redirect to={'/'}/>
         }
      }
     

      const Component = this.state.component ||
        <div className="loader-view"
             style={{height: 'calc(100vh - 200px)'}}>
           <Loader
                        type="ThreeDots"
                        color="#87D148"
                        height={70}
                        width={70}
                    />
        </div>;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
