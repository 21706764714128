import {
    REPORT_ESTATE_SERVICES,
    REPORT_ESTATE_GENERAL,
    REPORT_VEHICLE_GENERAL,
    REPORT_STORE_GENERAL,
    REPORT_USER_NOTIFICATIONS,
    REPORT_REMINDERS
   }
 from '../constants/ActionTypes';
 
 const INIT_STATE = {
   reportEstateServicesData: null,
   reportEstateGeneralData: null,
   reportVehicleGeneralData: null,
   reportStoreGeneralData: null,
   reportUserNotificationData: null,
   reportRemindersData: null
 };
 
 export default (state = INIT_STATE, action) => {
     switch(action.type) {
       case REPORT_ESTATE_SERVICES:
            return {
                ...state,
                reportEstateServicesData: action.payload
          }
        case REPORT_ESTATE_GENERAL:
          return {
              ...state,
              reportEstateGeneralData: action.payload
          }
        case REPORT_VEHICLE_GENERAL:
            return {
              ...state,
              reportVehicleGeneralData: action.payload
          }
          case REPORT_STORE_GENERAL:
            return {
              ...state,
              reportStoreGeneralData: action.payload
          }
          case REPORT_USER_NOTIFICATIONS:
            return {
              ...state,
              reportUserNotificationData: action.payload
          }
          case REPORT_REMINDERS:
            return {
              ...state,
              reportRemindersData: action.payload
          }
        default:
            return state;
     }
 }
 