import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {resetPass} from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";

const ChangePass = (props) => {

  var resetPassToken = "";
  if (props.match.params.resetPassToken){
    resetPassToken = props.match.params.resetPassToken;
  }

  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [networkErrorMessage, setNetWorkErrorMessage] = useState(null);

  const changePasswordResponse = useSelector(({auth}) => auth.resetPass);

  useEffect(() => {
      if (changePasswordResponse){
        if (changePasswordResponse.status){
          props.history.push('/');
        } else {
          setNetWorkErrorMessage(changePasswordResponse.message);
        }
      }
  }, [changePasswordResponse]);

  const changePassword = () => {
    let postData = {token: resetPassToken ,newPassword: password, verifyPassword: rePassword, langLoc: "el"};
    dispatch(resetPass(postData));
  }

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="GHPService">
            <img src={require("assets/images/logo.png")} alt="ghp" title="ghp"/>
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>Αλλαγή κωδικού πρόσβασης</h1>
          </div>

          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password"/>}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label="Επανάληψη κωδικού πρόσβαση"
                  fullWidth
                  onChange={(event) => setRePassword(event.target.value)}
                  defaultValue={rePassword}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button disabled={password.length < 6 || password == "" || rePassword == "" || password != rePassword} onClick={() => {
                    changePassword()
                  }} variant="contained" color="primary">
                    Αλλαγη
                  </Button>


                </div>

              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {networkErrorMessage && NotificationManager.error(networkErrorMessage)}
      <NotificationContainer/>

    </div>
  );
};


export default ChangePass;
