import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  CREATE_ESTATE_SERVICE,
  EDIT_ESTATE_SERVICE,
  SINGLE_ESTATE_SERVICE,
  FETCH_ESTATE_SERVICES,
  DELETE_ESTATE_SERVICE,
  EDIT_STATUS_SERVICE,
  FETCH_ESTATE_SERVICES_VIEW,
  DONE_SERVICE_SUBMIT,
  DONE_SERVICE_EDIT,
  EDIT_ESTATE_SERVICE_VEHICLE,
  FETCH_ESTATE_DAMAGES
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getEstateServices = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_SERVICES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getEstateServicesView = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/all-view', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_SERVICES_VIEW, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addEstateService = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/create', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_ESTATE_SERVICE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editEstateService = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_SERVICE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleEstateService = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_ESTATE_SERVICE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteEstateService = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ESTATE_SERVICE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editStatusEstateService = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/edit-status', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_STATUS_SERVICE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const doneServiceSubmit = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('doneservice/submit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DONE_SERVICE_SUBMIT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const doneServiceEdit = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('doneservice/edit', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DONE_SERVICE_EDIT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editEstateServiceVehicle = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('service/edit-service-vehicle', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_SERVICE_VEHICLE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const fetchEstateDamagesData = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('doneservice/damages', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_DAMAGES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};