import {ADD_DAMAGE_COMPANY,
  EDIT_DAMAGE_COMPANY,
  SINGLE_DAMAGE_COMPANY,
  FETCH_DAMAGE_COMPANIES,
  DELETE_DAMAGE_COMPANY
  } from '../constants/ActionTypes';

const INIT_STATE = {
  addDamageCompany: null,
  editDamageCompany: null,
  singleDamageCompany: null,
  damageCompanies: null,
  deleteDamageCompany: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case ADD_DAMAGE_COMPANY:
       return {
           ...state,
           addDamageCompany: action.payload
       }
   case EDIT_DAMAGE_COMPANY:
        return {
            ...state,
            editDamageCompany: action.payload
        }
  case SINGLE_DAMAGE_COMPANY:
       return {
         ...state,
         singleDamageCompany: action.payload
       }
  case FETCH_DAMAGE_COMPANIES:
      return {
          ...state,
        damageCompanies: action.payload
        }
  case DELETE_DAMAGE_COMPANY:
        return {
          ...state,
        deleteDamageCompany: action.payload
    }
  default:
        return state;
  }
}
