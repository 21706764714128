import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  ADD_BILL_CHECK,
  ADD_BILL_CHECK_PAY,
  DELETE_BILL_CHECK,
  DELETE_BILL_CHECK_PAY
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const updateBillCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/updateBillCheck', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_BILL_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateBillPay = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/updateBillPay', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_BILL_CHECK_PAY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteBillCheck = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/deleteBill', postData
    ).then(({data}) => {
      console.log(data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_BILL_CHECK, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteBillPay = (postData) => {
  // return (dispatch) => {
  //   dispatch({type: FETCH_START});
  //   axios.post('oil/delete', postData
  //   ).then(({data}) => {
  //     if (data){
  //       dispatch({type: FETCH_SUCCESS});
  //       dispatch({type: DELETE_BILL_CHECK_PAY, payload: data});
  //     } else {
  //       dispatch({type: FETCH_ERROR, payload: data.error});
  //     }
  //   }).catch(function (error) {
  //     dispatch({type: FETCH_ERROR, payload: error.message});
  //     console.log("Error****:", error.message);
  //   });
  // }
};
