import {FETCH_APARTMENTS,
  CREATE_APARTMENT,
  EDIT_APARTMENT,
  SINGLE_APARTMENT,
  DELETE_APARTMENT,
  CREATE_APARTMENT_TIMER,
  DELETE_APARTMENT_TIMER,
  EDIT_APARTMENT_TIMER,
  FETCH_APARTMENT_TIMERS} from '../constants/ActionTypes';

const INIT_STATE = {
  apartments: null,
  createApartment: null,
  editApartment: null,
  singleApartment: null,
  createTimer: null,
  deleteTimer: null,
  apartmentTimers: null,
  deleteApartment: null,
  editTimer: null
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
   case FETCH_APARTMENTS:
       return {
           ...state,
           apartments: action.payload
       }
   case CREATE_APARTMENT:
        return {
            ...state,
            createApartment: action.payload
        }
    case DELETE_APARTMENT_TIMER:
          return {
             ...state,
              deleteTimer: action.payload
        }
    case EDIT_APARTMENT_TIMER:
          return {
             ...state,
             editTimer: action.payload
        }
   case EDIT_APARTMENT:
         return {
             ...state,
             editApartment: action.payload
         }
  case SINGLE_APARTMENT:
         return {
             ...state,
             singleApartment: action.payload
         }
 case DELETE_APARTMENT:
          return {
            ...state,
            deleteApartment: action.payload
          }
  case CREATE_APARTMENT_TIMER:
          return {
            ...state,
              createTimer: action.payload
          }
  case FETCH_APARTMENT_TIMERS:
          return {
            ...state,
              apartmentTimers: action.payload
          }
  default:
        return state;
  }
}
