import {
    FETCH_DASHBOARD
  }
from '../constants/ActionTypes';

const INIT_STATE = {
  dashboardData: null,
};

export default (state = INIT_STATE, action) => {
  switch(action.type) {
  case FETCH_DASHBOARD:
             return {
             ...state,
             dashboardData: action.payload
      }
  default:
        return state;
  }
}
