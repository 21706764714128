import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_ESTATE_LAMPS,
  ADD_NEW_LAMP,
  EDIT_ESTATE_STATUS_LAMP,
  ADD_ESTATE_LAMP_CATEGORY,
  EDIT_ESTATE_LAMP_CATEGORY,
  FETCH_ESTATE_LAMPS_CATEGORIES,
  DELETE_ESTATE_LAMP_CATEGORY,
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getLampsCategories = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/category/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_LAMPS_CATEGORIES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addNewCategory = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/category/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_ESTATE_LAMP_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editNewCategory = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/category/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_LAMP_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteCategory = (postData) => {
  console.log(postData);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/category/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_ESTATE_LAMP_CATEGORY, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getLamps = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATE_LAMPS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const addNewLamp = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/add', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: ADD_NEW_LAMP, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editLampStatus = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('lamp/editStatus', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE_STATUS_LAMP, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
