import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_ESTATES_SUCCESS,
  CREATE_ESTATE,
  EDIT_ESTATE,
  SINGLE_ESTATE,
  USER_SERVICES_ESTATES
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getEstates = (userId, query, page, limit,sort,searchKeyword) => {
  let postData = {
      userId: userId,
      query: query,
      page: page,
      limit: limit,
      sort: sort,
      searchKeyword: searchKeyword
    }

    console.log(postData);

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/all', postData
    ).then(({data}) => {
      console.log("ESTATEDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_ESTATES_SUCCESS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createEstate = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/create', postData
    ).then(({data}) => {
      console.log("ESTATEDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_ESTATE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editEstate = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/edit', postData
    ).then(({data}) => {
      console.log("ESTATEDATA",data);
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_ESTATE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleEstate = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_ESTATE, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const postUserServicesEstateIds = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('estate/userServicesEstateIds', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_SERVICES_ESTATES, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


