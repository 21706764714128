import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SHOW_MESSAGE,
  FETCH_DATA,
  FETCH_APARTMENTS,
  CREATE_APARTMENT,
  EDIT_APARTMENT,
  DELETE_APARTMENT,
  SINGLE_APARTMENT,
  CREATE_APARTMENT_TIMER,
  FETCH_APARTMENT_TIMERS,
  DELETE_APARTMENT_TIMER,
  EDIT_APARTMENT_TIMER
} from "../constants/ActionTypes";
import axios from 'util/Api'

import {GENERAL_ERROR} from "../constants/values";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const getApartments = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/all', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_APARTMENTS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createApartment = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/create', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_APARTMENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editApartment = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/edit', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_APARTMENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const singleApartment = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/single', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SINGLE_APARTMENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const deleteApartment = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/delete', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_APARTMENT, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getApartmentTimers = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/timers', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: FETCH_APARTMENT_TIMERS, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const createApartmentTimer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/addTimer', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CREATE_APARTMENT_TIMER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const deleteApartmentTimer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/deleteTimer', postData
    ).then(({data}) => {
      if (data){
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: DELETE_APARTMENT_TIMER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const editApartmentTimer = (postData) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('apartment/editTimer', postData
    ).then(({data}) => {
      if (data){
        console.log(data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: EDIT_APARTMENT_TIMER, payload: data});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};
