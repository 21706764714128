import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import { USER_ROLE_ESTATE_ADMIN } from 'constants/values';

const SideBarContent = () => {

  const userData = useSelector(({auth}) => auth.authUser);

  let navigationMenus = [
    {
      name: 'pages.dashboard',
      type: 'item',
      icon: 'view-dashboard',
      link:'/app/dashboard'
    },
    {
      name: 'pages.estates',
      type: 'item',
      icon: 'city-alt',
      link:'/app/estates'
    },
    {
      name: 'pages.users',
      type: 'item',
      icon: 'accounts-alt',
      link:'/app/users'
    },
    {
      name: 'pages.user-estate-admins',
      type: 'item',
      icon: 'accounts-list-alt',
      link:'/app/estate-admins'
    },
    {
      name: 'pages.vehicles',
      type: 'item',
      icon: 'car',
      link:'/app/vehicles'
    },
    {
      name: 'pages.store',
      type: 'item',
      icon: 'store',
      link:'/app/store'
    },
    {
      name: 'pages.keysCategories',
      type: 'item',
      icon: 'key',
      link:'/app/keys/categories'
    },
    {
      name: 'pages.lampsCategories',
      type: 'item',
      icon: 'lamp',
      link:'/app/lamps/categories'
    },
    {
      name: 'pages.vehicleServiceCategories',
      type: 'item',
      icon: 'car',
      link:'/app/vehicle-service/categories'
    },
    {
      name: 'pages.servicesTasks',
      type: 'item',
      icon: 'washing-machine',
      link:'/app/service-tasks'
    },
    {
      name: 'pages.damageCompanies',
      type: 'item',
      icon: 'wrench',
      link:'/app/damage-companies'
    },
    {
        name: 'pages.notifications',
        type: 'item',
        icon: 'notifications',
        link:'/app/notifications'
    },
    {
        name: 'pages.reminders',
        type: 'item',
        icon: 'calendar-note',
        link:'/app/reminders'
    },
    {
      name: 'pages.questionnaire',
      type: 'item',
      icon: 'assignment-check',
      link:'/app/questionnaires'
    },
    {
      name: 'pages.reports',
      type: 'item',
      icon: 'collection-pdf',
      link:'/app/reports'
    },
    {
      name: 'pages.chat',
      type: 'item',
      icon: 'comments',
      link:'/app/chat'
    }
  ];


  if (userData.role == USER_ROLE_ESTATE_ADMIN){
    navigationMenus = [
      {
        name: 'pages.estates',
        type: 'item',
        icon: 'city-alt',
        link:'/app/estates'
      },
      {
        name: 'pages.reports',
        type: 'item',
        icon: 'collection-pdf',
        link:'/app/reports'
      },{
        name: 'pages.chat',
        type: 'item',
        icon: 'comments',
        link:'/app/chat'
      }
    ];
  }

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
